.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 85%;
    margin: 0px auto;
    margin-top: 30px;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-bottom: 40px;
    /* border: 1px solid red; */
    /* margin-bottom: 30px; */
}

.title {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
    
}

.tagline {
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    margin: 0px;
    margin-bottom: 40px;
    line-height: 30px;
}

.button {
    background-color: #32ff95;
    padding: 1rem 2rem;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 30px;
    /* margin-top: 10px;
    margin-bottom: 30px; */
}

.coreValuesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px auto;
    /* margin-bottom: 40px; */
}

.valueContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px;
    width: 70%;
    margin: 15px auto;
    margin-top: 0px;
    border: 3px solid #303030;
    border-radius: 50px;
}

.valueTitle {
    margin: 0px;
    padding: 0px;
    margin-bottom: 15px;
    color: white;
}

.valueDescription {
    margin: 0px;
    padding: 0px;
    /* margin-bottom: 15px; */
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.2rem;
}

.valueIcon {
    color: #32ff95;
    font-size: 6rem;
    margin-bottom: 20px;
}

.screenshotContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* width: 90%; */
    margin: 0px auto;
}

.screenshotImage {
    /* height: 600px; */
    border: 2px solid white;
    border-radius: 20px;
    width: 23%;
    max-width: 285px;
}

.divider {
    width: 90%;
    border-bottom: 1px solid #303030;
    margin: 20px auto;
}

.appStoreContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 130px;
    /* border: 1px solid white; */
}

.appStoreButton {
    background-color: black;
    border: none;
    cursor: pointer;
    height: 80px;
    width: 80px;
    max-width: 80px;
    max-height: 80px;
    min-width: 80px;
    min-height: 80px;
    /* border: 1px solid red; */
}

.appStoreIcon {
    height: 50px;
    width: 50px;
    max-width: 50px;
    max-height: 50px;
    min-width: 50px;
    min-height: 50px;
}

/* Desktop Size */
@media only screen and (min-width: 1200px) {
    .coreValuesContainer {
        flex-direction: row;
    }

    .valueContainer {
       width: 22%;
    }

    .container {
        margin-top: 20px;
    }
    
    .titleContainer {
        margin-top: 30px;
        margin-bottom: 60px;
    }
}