@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: rgba(255, 255, 255, 0.75);
    width: 95%;
    max-width: 500px;
    padding-top: 10px;
    padding-bottom: 20px;
    height: 30px;
}

.year {
    margin: 0px auto;
    color: rgba(255, 255, 255, 0.75);
    font-size: 1.1rem;
    flex-grow: 1;
}

.link {
    color: rgba(255, 255, 255, 0.75);
    /* margin: 0px 10px; */
    text-decoration: none;
    font-size: 1.1rem;
    font-family: "Roboto", sans-serif;
    text-align: center;
    flex-grow: 1;
}

.link:hover {
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}