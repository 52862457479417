@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.navMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 95%;
}

.hr {
    width: 100%;
    border: 1px solid #303030;
}

.link {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 1.3rem;
    font-family: "Roboto", sans-serif;
}

.link:hover {
    color: #999999;
}