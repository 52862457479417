@import url('https://fonts.googleapis.com/css2?family=Marck+Script&family=Schoolbell&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: black;
    width: 95%;
    max-width: 1200px;
    margin: 0 20px;
}

.logo {
    height: 50px;
    margin-right: 10px;
}

.innerHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0.5em;
}

.name {
    margin: 0px;
    font-size: 2.4rem;
    font-family: 'Marck Script', cursive;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.logoButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: black;
    border: none;
    cursor: pointer;
    /* border: 3px solid magenta; */
}

.linkContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-grow: 1;
    /* border: 3px solid magenta; */
}

.bars {
    font-size: 1.3rem;
    padding: 0.2rem;
    animation: fadeInAnimation ease 3s;
}

.close {
    font-size: 1.3rem;
    padding: 0.2rem;
    animation: fadeInAnimation ease 3s;
}

.link {
    display: none;
    font-size: 1.1rem;
    color: white;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.navButton {
    cursor: pointer;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.getStartedButton {
    display: none;
    cursor: pointer;
    background-color: #32ff95;
    padding: 10px 15px;
    border: none;
    border-radius: 10px;
    font-size: 0.9rem;
}

.leftButtonContainer {
    width: 220px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

}

.rightButtonContainer {
    width: 215px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.vetButton {
    background-color: black;
    padding: 10px 15px;
    /* padding: 10px 15px;
    border: none;
    cursor: pointer; */
    
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-size: 0.9rem;
    border-radius: 10px;
    /* width: 120px; */
    margin-right: 10px;
    background-color: #202020;
    color: rgba(255, 255, 255, 0.75);
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

/* Desktop Size */
@media only screen and (min-width: 1100px) {
    .link {
        display: inline;
        text-decoration: none;
    }

    .link:hover {
        color: #999999;
    }

    .navButton {
        display: none;
    }

    .getStartedButton {
        display: inline;
    }

    /* .vetButton {
        display: inline;
    } */
}