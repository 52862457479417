@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

p,
h1,
h2,
h3,
textarea,
input,
label,
ul,
li {
    color: white;
    font-family: "Roboto", sans-serif;
}

p {
    font-size: 1.2rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
}

h1 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 55px;
}

h2 {
    font-size: 2rem;
    font-weight: 500;
}

h3 {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.6px;
    text-align: center;
}

body {
    background-color: black;
    margin: 0;
    padding: 0;
}

button {
    font-size: 1.2rem;
    text-align: center;
    font-family: "Roboto", sans-serif;
    background-color: #32ff95;
    font-weight: 500;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    color: black;
}