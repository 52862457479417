
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-width: 340px; */
  max-width: 100vw;
  min-height: 100vh;
  background-color: black;
  margin: 0px auto;
  padding: 0px;
  /* border: 1px solid magenta; */
}