.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px auto;
    padding: 0px;
}

.subtitle {
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.75);
    text-align: center;
    margin: 0px;
}